import React, { useState } from "react";
import EmailIcon from "../icons/Email";
import PasswordIcon from "../icons/Password";

import { connect } from "react-redux";
import { logIn } from "../../actions";
import Alert from "../ui/Alert";

const Login = (props) => {

 
  let loginErrors = props.errors || []; 
  // console.log(process.env.REACT_APP_CMS_URL);

  // State variables for email and password inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Function to handle changes in email input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to handle changes in password input
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    props.login(email, password);
  };

  return (
    <div className="mx-auto flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
      <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
        Login To Your Account
      </div>
      <div className="mt-8">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="flex flex-col mb-2">
            <div className="flex relative ">
              {/* Email input */}
              <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                <EmailIcon />
              </span>
              <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Your email"
              />
            </div>
          </div>
          <div className="flex flex-col mb-6">
            <div className="flex relative ">
              {/* Password input */}
              <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                {/* Password icon */}
                <PasswordIcon />
              </span>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Your password"
              />
            </div>
          </div>
          <div className="flex items-center mb-6 -mt-4">
            <div className="flex ml-auto">
              <a
                href="/forgot-password"
                className="inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white"
              >
                Forgot Your Password?
              </a>
            </div>
          </div>
          <div className="flex w-full">
            {/* Login button */}
            <button
              type="submit"
              className="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Login
            </button>
          </div>
        </form>
      </div>
 
          {loginErrors.map((err, index) => {
            return (
              <div className="my-4" key={"error_" + index}>
                <Alert message={err.message} type="danger" />
              </div>
            );
          })}
    
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(logIn(email, password)),
  };
}

function mapStateToProps({ errors }) {
  return {errors};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
