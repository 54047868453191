import React  from "react";

import { useLocation } from "react-router-dom";

import DropDownMenu from "./Dropdown";
import Account from "./icons/Account"; 

import { connect } from "react-redux";
import LogoIcon from "./icons/Logo";

const Header = (props) => {
  
  // console.log(`Header props: `, props)


  const location = useLocation();

  let accountItems = [];
  let mainItems = [];
    if(!props.user){
      accountItems = [
        {
          label: "Log in",
          link: "/",
        },
        {
          label: "Sign-up",
          link: "/sign-up",
        },
      ];
 
  } else {

    accountItems = [
      {
        label: "Editer mes infos",
        link: "/account",
      }, 
      {
        label: "Déconnexion",
        link: "/logout",
      }, 
    ];

    mainItems = [
      {
        label: "Importer une commande",
        link: "/",
      }, 
      {
        label: "Historique des imports",
        link: "/history",
      }, 
    ]
  } 

 

  const listMainItems = (classStr) => {
    return mainItems.map((item, i) => {
      return (
        <a
          className={
            location.pathname === item.link
              ? classStr.replace("text-gray-500", "text-gray-800 dark:text-white underline")
              : classStr
          }
          key={i}
          href={item.link}
        >
          {item.label}
        </a>
      );
    });
 
  };

  return (
    <div>
      <nav className="bg-white dark:bg-gray-800 shadow">
        <div className="md:container md:mx-auto ">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <a className="flex-shrink-0 text-gray-800 dark:text-white  p-3 rounded-lg font-bold uppercase  " href="/">
               <div className="flex">
               <LogoIcon/>
               <span className="hidden md:inline">
               AnkWooStore
               </span>
               
               </div>
                
              </a>
              <div className="hidden md:block">
                <div className="flex items-baseline ml-10 space-x-4">
                  { 
                    listMainItems(
                      "text-gray-600 hover:text-gray-800 dark:text-gray-200 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    )}
                </div>
              </div>
            </div>
            <div className="block">
              <div className="flex items-center lg:ml-4 md:ml-2">
                <div className="relative ml-3">

                  <DropDownMenu icon={<Account />} label={props.user ? props.user.email : 'Welcome'} items={accountItems} />
                
                </div>
              </div>
            </div>
             
          </div>
        </div>
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            { 
              listMainItems(
                "text-gray-300 hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              )}
          </div>
        </div>
      </nav>
    </div>
  );
};

function mapStateToProps({user}){
  return {user }
}

export default connect(mapStateToProps)(Header);
