import React from "react";

const Alert = ({ type, message, link, cta, cta_title, target = "_blank" }) => {
  let alertClass = "";
  let btnClass = "";
  target = target ? target : "_blank";
  switch (type) {
    case "danger":
      alertClass = "bg-red-100 border-red-400 text-red-700";
      btnClass = "bg-red-500  hover:bg-red-600 focus:ring-red-600";
      break;
    case "warning":
      alertClass = "bg-yellow-100 border-yellow-400 text-yellow-700";
      btnClass = "bg-yellow-500  hover:bg-yellow-600 focus:ring-yellow-600";
      break;
    case "success":
      alertClass = "bg-green-100 border-green-400 text-green-700";
      btnClass = "bg-green-500  hover:bg-green-600 focus:ring-green-600";
      break;
    case "primary":
      alertClass = "bg-purple-100 border-purple-400 text-purple-700";
      btnClass = "bg-purple-500  hover:bg-purple-600 focus:ring-purple-600";
      break;
    default:
      alertClass = "bg-gray-100 border-gray-400 text-gray-700";
      btnClass = "bg-gray-500  hover:bg-gray-600 focus:ring-gray-600";
  }

  const handleClick = () => {
    window.open(link, target);
    // console.log(target);
    // console.log(link);
  };

  return (
    <div
      className={`border  px-4 py-3 mb-2 rounded relative  flex items-center justify-between ${alertClass}`}
      role="alert"
    >
      <span className="block text-center w-full sm:inline">{message}</span>

      {link && cta && (
        <button
          title={cta_title}
          onClick={handleClick}
          className={`ml-2 px-3 py-1 rounded ${btnClass} text-white focus:outline-none focus:ring-2  focus:ring-opacity-50`}
        >
          {cta}
        </button>
      )}
    </div>
  );
};

export default Alert;
