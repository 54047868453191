import { combineReducers } from "redux";

// Import action types
import {
  FETCH_USER,
  LOG_OUT,
  LOG_IN,
  LOG_IN_FAIL,
  IMPORTING_ANKORSTORE_ORDER,
  IMPORTED_ANKORSTORE_ORDER,
  VERIFYING_ANKORSTORE_ORDER,
  VERIFIED_ANKORSTORE_ORDER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  FETCHED_IMPORTED_ORDERS,
  FETCHING_IMPORTED_ORDERS,
} from "../actions/types";

// Reducers


const errorsReducer = (state =null, action) => {
  switch (action.type) {
    

    case LOG_IN_FAIL:
      return action.payload.errors;

    default:
      return state;
  }
};



const authReducer = (state = null, action) => {
  // console.log(action);

  switch (action.type) {
    case FETCH_USER:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return action.payload.user || false;

    case LOG_OUT:
      return false;

    case LOG_IN:
      return action.payload.user;

    default:
      return state;
  }
};

const importingReducer = (state = null, action) => {
  switch (action.type) {
    case IMPORTING_ANKORSTORE_ORDER:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return { importing: action.payload } || false;

    case IMPORTED_ANKORSTORE_ORDER:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return false;

    default:
      return state;
  }
};

const importedReducer = (state = null, action) => {
  switch (action.type) {
    case IMPORTED_ANKORSTORE_ORDER:
      return action.payload || false;

    case VERIFYING_ANKORSTORE_ORDER:
      return false;
    default:
      return state;
  }
};

const verifyingReducer = (state = null, action) => {
  switch (action.type) {
    case VERIFYING_ANKORSTORE_ORDER:
      return action.payload || false;

    case VERIFIED_ANKORSTORE_ORDER:
      return false;

    default:
      return state;
  }
};

const verifiedReducer = (state = null, action) => {
  switch (action.type) {
    case VERIFYING_ANKORSTORE_ORDER:
      return false;

    case VERIFIED_ANKORSTORE_ORDER:
      return action.payload || false;

    case IMPORTED_ANKORSTORE_ORDER:
      return action.payload.success ? false : state;

    default:
      return state;
  }
};

const updateUser = (state = null, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return "updating";
    case UPDATE_USER_SUCCESS:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return action.payload || false;
    case UPDATE_USER_FAILURE:
      // request not complete action.payload.user == undefined
      // request succeeded user not logged in action.payload.user == false
      // request succeeded user is logged in action.payload.user == userObject
      return action.payload || false;

    default:
      return state;
  }
};

const fetchImportedOrders = (state = null, action) => {
  switch (action.type) {
    case FETCHING_IMPORTED_ORDERS:
      return "updating";
    case FETCHED_IMPORTED_ORDERS:
      return action.payload || false;

    default:
      return state;
  }
};

// Combine Reducers
const rootReducer = combineReducers({
  user: authReducer,
  userUpdate: updateUser,
  importing: importingReducer,
  imported: importedReducer,
  verified: verifiedReducer,
  verifying: verifyingReducer,
  imported_orders: fetchImportedOrders,
  errors:errorsReducer,
});

export default rootReducer;
