import React, { useEffect, useState, useRef } from "react";
import { w3cwebsocket as WebSocketClient } from "websocket";

import { connect } from "react-redux";

const CMS_URL = process.env.REACT_APP_CMS_URL;

const Terminal = ({ verified, imported, user }) => {
  const [logs, setLogs] = useState([]);
  const logsEndRef = useRef(null);

  const [copyButtonText, setCopyButtonText] = useState("Copier les logs");
  const [isConsoleVisible, setIsConsoleVisible] = useState(false); // State to manage visibility

  const scrollToBottom = () => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const ws = new WebSocketClient(`wss://${CMS_URL}/ws`);
    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (message) => {
      setLogs((prevLogs) => [...prevLogs, message.data]);
      scrollToBottom();
    };

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (verified?.data?.success) {
      setIsConsoleVisible(false);
    }
  }, [verified]);

  useEffect(() => {
    if (imported?.success) {
      setIsConsoleVisible(false);
    }
  }, [imported]);

  const handleCopyToClipboard = () => {
    const plainTextToCopy = logs.map((log) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = log;
      return tempElement.textContent || tempElement.innerText || "";
    });
    plainTextToCopy.unshift(`********************************************
******************* LOGS *******************
********************************************`);
    plainTextToCopy.push(`********************************************
**************** END OF LOGS ***************
********************************************`);
    const plainText = plainTextToCopy.join("\n\n");

    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        setCopyButtonText(" [ Logs copiés! ]");
        setTimeout(() => {
          setCopyButtonText(""); // Clear status after a delay
        }, 2000); // Clear status after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy logs to clipboard:", error);
        setCopyButtonText(" [erreur lors de la copie des logs]");
        setTimeout(() => {
          setCopyButtonText(""); // Clear status after a delay
        }, 2000); // Clear status after 2 seconds
      });
  };

  const handleClearLogs = () => {
    setLogs([]);
  };

  const toggleConsoleVisibility = () => {
    setIsConsoleVisible(!isConsoleVisible);
  };

  return (
    <div className="h-[calc(100vh-200px)] flex flex-col">
      {logs.length > 0 && (
        <button
          onClick={toggleConsoleVisibility}
          className="p-3  rounded bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50 mb-4"
        >
          {isConsoleVisible
            ? "Masquer la console"
            : "Voir la console (afficher les logs)"}
        </button>
      )}
      {isConsoleVisible && logs.length > 0 && (
        <div
          id="console-logs"
          className="flex-1 overflow-y-auto bg-black text-gray-100 p-4 relative rounded-lg shadow-md"
        >
          <div className="flex justify-between mb-2 sticky top-2 right-2">
            <button
              onClick={handleClearLogs}
              className="px-3 py-1 rounded mr-4 bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
            >
              Effacer les logs
            </button>
            <button
              onClick={handleCopyToClipboard}
              className=" px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5 5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2h3a1 1 0 0 1 0 2h-1v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7H3a1 1 0 0 1 0-2h2zm2-1a1 1 0 0 0-1 1v1h8V5a1 1 0 0 0-1-1H7zm3 6a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2h-4z"
                  clipRule="evenodd"
                />
              </svg>
              {copyButtonText ? copyButtonText : "Copier les logs"}
            </button>
          </div>

          {logs.map((log, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: log }}></p>
          ))}
          <div ref={logsEndRef}></div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps({ imported, verified, user }) {
  return {
    verified,
    imported,
  };
}
export default connect(mapStateToProps)(Terminal);
