const CheckboxInput = (props) => {
  return (
    <div className="flex flex-col mb-2 rounded p-1">
      <div className="flex relative"> 
        <span className="rounded-l-md w-1/5 inline-flex items-center p-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
          {props.label}
        </span>
        <label
          htmlFor={props.id}
          className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 inline-flex items-center cursor-pointer"
        >
          <input
            type="checkbox"
            id={props.id}
            onChange={props.onChange}
            checked={props.checked}
            className="form-checkbox mr-2 h-4 w-4 text-purple-600 transition duration-150 ease-in-out"
          />
          {props.optionLabel? props.optionLabel : props.label}
        </label>
      </div>
    </div>
  );
};

export default CheckboxInput;
