
import React  from "react";

import { connect } from "react-redux"; 

import Alert from "../ui/Alert";

const SettingsSumary = (props) => {
    let testModeLabel = props.wc_use_test_credentials ? "Mode test :" : "Mode prod :";

    return(
        <div className="my-4">

{props.wc_use_test_credentials  ? (
            <Alert message={testModeLabel + ` ${props.wc_test_base_url}   ` } 
            
            type="success" />
          ) : (
            <Alert message={testModeLabel + ` ${props.wc_base_url} ` }

             type="warning" />
          )}
        </div>
    );

}

const mapStateToProps = ({ user, userUpdate }) => {
    return { 
      ankorstore_client_id: user.ankorstore_client_id,
       
      wc_base_url: user.wc_base_url, 
       
      wc_use_test_credentials: user.wc_use_test_credentials,
      wc_test_base_url: user.wc_test_base_url, 
    };
  };
  
  export default connect(mapStateToProps)(SettingsSumary);
  