import { Component } from "react";
import { connect } from "react-redux";

import Login from "../forms/Login";
import { Navigate } from "react-router-dom";

class Landing extends Component {
  renderContent() {
    switch(this.props.user){
        case false:
        return <Login/>;
        default:
        return <Navigate to="/dashboard" />;
    }
  }

  render() {
    return (
      <div className="md:container md:mx-auto">
        <div className="grid grid-cols-1 my-8 gap-4">
        {this.renderContent()}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Landing);
