import React, { useState } from 'react';
import { logOut } from '../actions';
 
import { useDispatch } from 'react-redux';


 
const DropDownMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    
  const dispatch = useDispatch();

    const handleLogOut = (e) => {
        e.preventDefault();
        dispatch(logOut());
    }


    // console.log(`dropdown props`, props)
    
    return (
        <div className="relative inline-block text-left " >
            <div>
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className={` ${
                        props.withBackground ? 'border border-gray-300 bg-white dark:bg-gray-800 shadow-sm' : ''
                    } flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500`}
                    id="options-menu"
                >

                    <span className='pr-2'>
                    {props.label}
                    </span>

                    {props.icon || ( 
                        <svg  
                            width="20"
                            height="20" 
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z" />
                        </svg> 
                    )}
                </button>
            </div>

            {(props.forceOpen || isOpen) && (
                <div className="absolute dropdown_menu right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                    <div
                        className={`py-1 ${props.withDivider ? 'divide-y divide-gray-100' : ''}`}
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {props.items.map((item) => {

                            if (item.link === '/logout'){
                                return (
                                    <a
                                    key={item.label}
                                    href="#user_menu"
                                    onClick={handleLogOut}
                                    className={`${
                                        item.icon ? 'flex items-center' : 'block'
                                    } block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600`}
                                    role="menuitem"
                                >
                                    {item.icon}

                                    <span className="flex flex-col">
                                        <span>{item.label}</span>
                                        {item.desc && <span className="text-xs text-gray-400">{item.desc}</span>}
                                    </span>
                                </a>
                                )
                            } else {
                            return (
                                <a
                                    key={item.label}
                                    href={item.link || '#'}
                                    className={`${
                                        item.icon ? 'flex items-center' : 'block'
                                    } block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600`}
                                    role="menuitem"
                                >
                                    {item.icon}

                                    <span className="flex flex-col">
                                        <span>{item.label}</span>
                                        {item.desc && <span className="text-xs text-gray-400">{item.desc}</span>}
                                    </span>
                                </a>
                            );}
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

 

export default  DropDownMenu;