import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchImportedOrders } from "../../actions";

const ImportHistory = ({ user, imported_orders, fetchImportedOrders }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if user exists and redirect if not
    if (user === false) {
      navigate("/");
    }

    if (user && user.roles.some((role) => role === "admin")) {
      setIsAdmin(true);
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      fetchImportedOrders(currentPage);
    }
  }, [user, fetchImportedOrders, currentPage]);

  useEffect(() => {
    if (!imported_orders) {
      setLoading(true);
      setError(null);
    } else if (imported_orders && imported_orders.docs) {
      setLoading(false);
      setError(null);
    } else if (imported_orders.error) {
      setError(imported_orders.error);
      setLoading(false);
    }
  }, [imported_orders]);

  if (loading)
    return (
      <div className="md:container md:mx-auto">
        <div className="p-4 text-center">Loading...</div>
      </div>
    );
  if (error)
    return (
      <div className="md:container md:mx-auto">
        <div className="p-4 text-center">
          <div>Error: {error}</div>
        </div>
      </div>
    );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let wc_link_base = "";
  if (user) {
    wc_link_base =
      user.wc_use_test_credentials === true
        ? user.wc_test_base_url
        : user.wc_base_url;
  }

  let ankorstore_link_base = "";
  if (user) {
    ankorstore_link_base =
      user.ankorstore_use_test_credentials === true
        ? user.ankorstore_test_base_url
        : user.ankorstore_base_url;
  }

  return (
    <div className="md:container md:mx-auto">
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4 text-center py-12">
          Historique des commandes Ankorstore importées dans WooCommerce
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse rounded-lg border-slate-500 border text-center">
            <thead>
              <tr className="bg-transparent dark:text-gray-100 dark:bg-gray-800 rounded-lg">
                <th className="p-4  border-slate-500 border text-center w-[5%]">
                  Mode
                </th>
                <th className="p-4  border-slate-500 border text-center w-[25%]">
                  Client
                </th>
                <th className="p-4  border-slate-500 border text-center">
                  Ref. Ankorstore
                </th>
                <th className="p-4 border-slate-500 border text-center">
                  WooCommerce Order ID
                </th>
                <th className="p-4 border-slate-500 border text-center">
                  Date
                </th>
                <th className="p-4 border-slate-500 border text-center">
                  Résultat
                </th>
                <th className="p-4 border-slate-500 border text-center">
                  Notes
                </th>
                {isAdmin && (
                  <th className="p-4 border-slate-500 border text-center">
                    Imported By
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {imported_orders.docs.map((item) => (
                <tr
                  key={item.id}
                  className={
                    item.mode === "test"
                      ? "import_details bg-gray-300 text-slate-600 font-italic test_row "
                      : "import_details  "
                  }
                >
                  <td className="p-4 border-slate-500 border text-center uppercase">
                    <div
                      className={
                        item.mode === "test"
                          ? "bg-gray-300 text-slate-600 font-italic "
                          : " "
                      }
                    >
                      {item.mode}
                    </div>
                  </td>
                  <td className="p-4 border-slate-500 border text-center client_details">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.clientDetails }}
                    />
                  </td>
                  <td className="p-4 border-slate-500 border text-center">
                    {item.ankorstoreOrderId ? (
                      <a
                        href={`${ankorstore_link_base}account/orders/${item.ankorstoreOrderId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-green-700 hover:underline font-bold"
                      >
                        {" "}
                        {item.ankorstoreOrderRef}
                      </a>
                    ) : (
                      <span>{item.ankorstoreOrderRef}</span>
                    )}
                  </td>
                  <td className="p-4 border-slate-500 border text-center">
                    <a
                      href={`${wc_link_base}wp-admin/post.php?post=${item.wcOrderId}&action=edit`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-green-700 hover:underline font-bold"
                    >
                      {item.wcOrderId || "N/A"}
                    </a>
                  </td>
                  <td className="p-4 border-slate-500 border text-center">
                    {new Date(item.importDate).toLocaleString()}
                  </td>
                  <td className="p-4 border-slate-500 border text-center">
                    <span
                      className={
                        item.outcome === "success"
                          ? "text-green-500 font-bold italic"
                          : "text-red-100 font-bold italic"
                      }
                    >
                      {item.outcome}
                    </span>
                  </td>
                  <td className="p-4 border-slate-500 border text-center">
                    {item.wcOrderNotes.map((note, index) => (
                      <p key={index} className="mb-6">
                        {note.content}
                      </p>
                    ))}
                  </td>
                  {isAdmin && (
                    <td className="p-4 border-slate-500 border text-center">
                      {item.importedBy.email}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center mt-4">
          {imported_orders.hasPrevPage && (
            <button
              onClick={() => paginate(currentPage - 1)}
              className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
            >
              Précédents
            </button>
          )}

          {imported_orders.hasNextPage && (
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={!imported_orders.hasNextPage}
              className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
            >
              Suivants
            </button>
          )}
        </div>
        <p className="text-center mt-2">
          Page {imported_orders.page} de {imported_orders.totalPages}
        </p>
      </div>
    </div>
  );
};

function mapStateToProps({ user, imported_orders }) {
  return { user, imported_orders };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchImportedOrders: (page) => dispatch(fetchImportedOrders(page)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportHistory);
