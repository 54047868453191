import React, { Component } from "react";

// Router setup here
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { connect } from 'react-redux';
import * as actions from './actions';


import Header from "./components/Header";


// Page components
import Landing from "./components/pages/Landing";
import Dashboard from "./components/pages/Dashboard";
import Onboarding from "./components/pages/Onboarding";
import Account from "./components/pages/Account";
import ImportHistory from "./components/pages/ImportHistory";


// BrowserRouter must have at most 1 child component
// Router matches every single path, by default
// Use exact to only match one at a time
class App extends Component {

  componentDidMount(){
    this.props.fetchUser();
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Header />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="account" element={<Account />} />
              <Route path="sign-up" element={<Onboarding />} />
              <Route path="history" element={<ImportHistory />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
// Wiring up the action creators of Redux
// Actions are assigned the the App Component as props
export default connect(null, actions)(App);
