import axios from "axios";
import {
  FETCH_USER,
  LOG_OUT,
  LOG_IN,
  LOG_IN_FAIL,
  IMPORTED_ANKORSTORE_ORDER,
  IMPORTING_ANKORSTORE_ORDER,
  VERIFYING_ANKORSTORE_ORDER,
  VERIFIED_ANKORSTORE_ORDER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCHED_IMPORTED_ORDERS,
} from "./types";

/**
 *
 * AUTHENTICATION
 */
const fetchUser = () => {
  return async function (dispatch) {
    let res = await axios.get("/api/users/me");
    dispatch({ type: FETCH_USER, payload: res.data });
  };
};

const logOut = () => {
  return async function (dispatch) {
    await axios.post("/api/users/logout", {}, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
    });
    dispatch({ type: LOG_OUT, payload: false });
  };
};

const logIn = (email, password) => {
  // console.log(response);
  return async function (dispatch) {
    try {
      // Send login request to the Payload Local API
      const req = await fetch(`/api/users/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const response = await req.json();

      if (response.user) {
        // Successful login, handle accordingly (e.g., redirect)
        console.log(`Logged in as ${response.user.email}`);
        dispatch({ type: LOG_IN, payload: response });
      } else {
        // Failed login, handle accordingly (e.g., display error message)
        console.error("Login failed");

        dispatch({ type: LOG_IN_FAIL, payload: response });
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
};



/**
 *
 * ORDER IMPORT FUNCTIONS
 *
 */
const importAnkorstoreOrder = (
  ankorstoreOrderToImport,
  orderLines,
  markPaid,
  customOrderNote
) => {
  return async function (dispatch) {
    // accept dispatch as an argument here
    try {
      dispatch({
        type: IMPORTING_ANKORSTORE_ORDER,
        payload: ankorstoreOrderToImport.ref,
      });

      let res = await axios.post("/custom/import-ankorstore-order", {
        ankorstoreOrderToImport,
        orderLines,
        markPaid,
        customOrderNote
      });

      // console.log(res.data);

      dispatch({
        type: IMPORTED_ANKORSTORE_ORDER,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

const verifyAnkorstoreOrder = (orderNumber, forceImportExisting) => {
  return async function (dispatch) {
    // accept dispatch as an argument here
    try {
      dispatch({
        type: VERIFYING_ANKORSTORE_ORDER,
        payload: orderNumber,
      });

      let res = await axios.post(
        "/custom/import-ankorstore-order/verify-order/",
        {
          orderNumber,
          forceImportExisting
        }
      );

      // console.log(res.data);

      dispatch({
        type: VERIFIED_ANKORSTORE_ORDER,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchImportedOrders = (page = 1) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/imported_orders?limit=20&page=${page}`); 
      dispatch({
          type: FETCHED_IMPORTED_ORDERS,
          payload: response.data,
      });
    } catch (error) {
       
    }  
  }
}

/** PROFILE ACTIONS */

// Action Creators
export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: { 
    error: error,
  },
});

const updateUserAccount = (userId, userData) => {
  return async (dispatch) => {
    dispatch(updateUserRequest());
    try {
      const response = await axios.patch(`/api/users/${userId}`, userData);
      dispatch(updateUserSuccess({ success: true, data: response.data }));
    } catch (error) {
      dispatch(updateUserFailure(error.response.data));
    }
  };
};

export {
  fetchUser,
  logIn,
  logOut,
  importAnkorstoreOrder,
  verifyAnkorstoreOrder,
  updateUserAccount,
  fetchImportedOrders,
};
