export const LOG_OUT = "log_out";
export const LOG_IN = "log_in";
export const LOG_IN_FAIL = "log_in_fail";
export const FETCH_USER = "fetch_user";
export const IMPORTING_ANKORSTORE_ORDER = "importing_ankorstore_order";
export const IMPORTED_ANKORSTORE_ORDER = "imported_ankorstore_order";
export const VERIFYING_ANKORSTORE_ORDER = "verifying_ankorstore_order";
export const VERIFIED_ANKORSTORE_ORDER = "verified_ankorstore_order";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
/* FETCH IMPORT HISTORY */
export const FETCHED_IMPORTED_ORDERS = "fetch_imported_orders";
export const FETCHING_IMPORTED_ORDERS = "fetching_imported_orders";
