const LogoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
      className="mr-3"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12 21l-8-4.5v-9L12 3l8 4.5V12m-8 0l8-4.5M12 12v9m0-9L4 7.5M22 18h-7m3-3l-3 3l3 3"
      />
    </svg>
  );
};

export default LogoIcon;
