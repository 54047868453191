import React, { useState } from "react";

import { connect } from "react-redux";
import { updateUserAccount } from "../../actions";

import Alert from "../ui/Alert";
import TextInput from "../ui/TextInput";
import CheckboxInput from "../ui/ChecboxInput";

const UpdateAccount = (props) => {
  // console.log("UpdateAccount props: ", props);

  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phone || "");
  const [first_name, setFirstName] = useState(props.first_name || "");
  const [last_name, setLastName] = useState(props.last_name || "");

  const [
    ankorstoreUseTestCredentials,
    setAnkorstoreUseTestCredentials,
  ] = useState(props.ankorstore_use_test_credentials);

  const [ankorstoreBaseUrl, setAnkorstoreBaseUrl] = useState(
    props.ankorstore_base_url
  );
  const [ankorstoreClientId, setAnkorstoreClientId] = useState(
    props.ankorstore_client_id
  );
  const [ankorstoreClientSecret, setAnkorstoreClientSecret] = useState(
    props.ankorstore_client_secret
  );

  const [ankorstoreTestBaseUrl, setAnkorstoreTestBaseUrl] = useState(
    props.ankorstore_test_base_url
  );
  const [ankorstoreTestClientId, setAnkorstoreTestClientId] = useState(
    props.ankorstore_test_client_id
  );
  const [ankorstoreTestClientSecret, setAnkorstoreTestClientSecret] = useState(
    props.ankorstore_test_client_secret
  );

  const [wcUseTestCredentials, setWcUseTestCredentials] = useState(
    props.wc_use_test_credentials
  );

  const [wcBaseUrl, setWcBaseUrl] = useState(props.wc_base_url);
  const [wcClientId, setWcClientId] = useState(props.wc_client_id);
  const [wcClientSecret, setWcClientSecret] = useState(props.wc_client_secret);

  const [wcTestBaseUrl, setWcTestBaseUrl] = useState(props.wc_test_base_url);
  const [wcTestClientId, setWcTestClientId] = useState(props.wc_test_client_id);
  const [wcTestClientSecret, setWcTestClientSecret] = useState(
    props.wc_test_client_secret
  );

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    let userData = {
      email,
      wc_base_url: wcBaseUrl,
      wc_client_id: wcClientId,
      wc_client_secret: wcClientSecret,
      wc_use_test_credentials: wcUseTestCredentials,
      wc_test_base_url: wcTestBaseUrl,
      wc_test_client_id: wcTestClientId,
      wc_test_client_secret: wcTestClientSecret,
      ankorstore_use_test_credentials: ankorstoreUseTestCredentials,
      ankorstore_base_url: ankorstoreBaseUrl,
      ankorstore_client_id: ankorstoreClientId,
      ankorstore_client_secret: ankorstoreClientSecret,
      ankorstore_test_base_url: ankorstoreTestBaseUrl,
      ankorstore_test_client_id: ankorstoreTestClientId,
      ankorstore_test_client_secret: ankorstoreTestClientSecret,
    };

    props.updateUserAccount(props.user_id, userData);
  };

  const handleFieldChange = async (event) => {
    let field = event.target.id;
    let val = event.target.value;

    const { type, checked, value } = event.target;
    var fieldValue = "";

    switch (field) {
      case "email":
        setEmail(val);
        break;

      case "phone":
        setPhone(val);
        break;

      case "first_name":
        setFirstName(val);
        break;

      case "last_name":
        setLastName(val);
        break;

      case "ankorstore_use_test_credentials":
        fieldValue = type === "checkbox" ? checked : value;
        setAnkorstoreUseTestCredentials(fieldValue);
        break;

      case "ankorstore_base_url":
        setAnkorstoreBaseUrl(val);
        break;
      case "ankorstore_client_id":
        setAnkorstoreClientId(val);
        break;
      case "ankorstore_client_secret":
        setAnkorstoreClientSecret(val);
        break;

      case "ankorstore_test_base_url":
        setAnkorstoreTestBaseUrl(val);
        break;
      case "ankorstore_test_client_id":
        setAnkorstoreTestClientId(val);
        break;
      case "ankorstore_test_client_secret":
        setAnkorstoreTestClientSecret(val);
        break;

      case "wc_use_test_credentials":
        fieldValue = type === "checkbox" ? checked : value;
        setWcUseTestCredentials(fieldValue);
        break;

      case "wc_base_url":
        setWcBaseUrl(val);
        break;
      case "wc_client_id":
        setWcClientId(val);
        break;
      case "wc_client_secret":
        setWcClientSecret(val);
        break;

      case "wc_test_base_url":
        setWcTestBaseUrl(val);
        break;
      case "wc_test_client_id":
        setWcTestClientId(val);
        break;
      case "wc_test_client_secret":
        setWcTestClientSecret(val);
        break;

      default:
    } 

  };

  return (
    <div className="mx-auto flex flex-col w-full   px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
      <div className=" text-center self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
        Mettre a jour mes informations
      </div>

      {props.userUpdate && props.userUpdate.success && (
        <Alert
          message={`Vos informations ont été mises à jour
            `}
          link={`/dashboard/`}
          cta="Aller au tableau de bord"
          target="_self"
          type="success"
        />
      )}

      <div className="my-4">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid gap-4 lg:grid-cols-2 mb-4">
            <TextInput
              label="Prénom"
              id="first_name"
              value={first_name}
              onChange={handleFieldChange}
              classes=" "
              placeholder="Prénom"
            />

            <TextInput
              label="Adresse Email"
              id="email"
              value={email}
              onChange={handleFieldChange}
              classes=" "
              placeholder="votre@adresse.mail"
            />

            <TextInput
              label="Nom"
              id="last_name"
              value={last_name}
              onChange={handleFieldChange}
              classes=" "
              placeholder="Nom"
            />

            <TextInput
              label="Téléphone"
              id="phone"
              value={phone}
              onChange={handleFieldChange}
              classes=" "
              placeholder="0633064406"
            />
          </div>

          <div className="grid gap-8 lg:grid-cols-2 ">
            <div className="mb-4">
              <h2 className="text-2xl text-black dark:text-gray-200 text-center my-3">
                {" "}
                Identifiants WooCommerce{" "}
              </h2>

              <CheckboxInput
                label="WooCommerce en MODE TEST ?"
                optionLabel="Utiliser les identifiants test pour WooCommerce"
                id="wc_use_test_credentials"
                checked={wcUseTestCredentials}
                onChange={handleFieldChange}
              />

              <TextInput
                label="wc_base_url"
                id="wc_base_url"
                value={wcBaseUrl}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={wcUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="wc_client_id"
                id="wc_client_id"
                value={wcClientId}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={wcUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="wc_client_secret"
                id="wc_client_secret"
                value={wcClientSecret}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={wcUseTestCredentials}
                placeholder="***************************************************************"
              />

              <TextInput
                label="wc_test_base_url"
                id="wc_test_base_url"
                value={wcTestBaseUrl}
                onChange={handleFieldChange}
                classes="bg-green-500 "
                hide={!wcUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="wc_test_client_id"
                id="wc_test_client_id"
                value={wcTestClientId}
                onChange={handleFieldChange}
                classes="bg-green-500 "
                hide={!wcUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="wc_test_client_secret"
                id="wc_test_client_secret"
                value={wcTestClientSecret}
                onChange={handleFieldChange}
                classes="bg-green-500 "
                hide={!wcUseTestCredentials}
                placeholder="***************************************************************"
              />
            </div>
            <div className="mb-4">
              <h2 className="text-2xl text-black dark:text-gray-200 text-center my-3">
                {" "}
                Identifiants Ankorstore{" "}
              </h2>

              <CheckboxInput
                label="Ankorstore en MODE TEST ?"
                optionLabel="Utiliser les identifiants test pour Ankorstore"
                id="ankorstore_use_test_credentials"
                checked={ankorstoreUseTestCredentials}
                onChange={handleFieldChange}
              />

              <TextInput
                label="ankorstore_base_url"
                id="ankorstore_base_url"
                value={ankorstoreBaseUrl}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={ankorstoreUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="ankorstore_client_id"
                id="ankorstore_client_id"
                value={ankorstoreClientId}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={ankorstoreUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="ankorstore_client_secret"
                id="ankorstore_client_secret"
                value={ankorstoreClientSecret}
                onChange={handleFieldChange}
                classes=" bg-red-500 "
                hide={ankorstoreUseTestCredentials}
                placeholder="***************************************************************"
              />

              <TextInput
                label="ankorstore_test_base_url"
                id="ankorstore_test_base_url"
                value={ankorstoreTestBaseUrl}
                onChange={handleFieldChange}
                classes=" bg-green-500 "
                hide={!ankorstoreUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="ankorstore_test_client_id"
                id="ankorstore_test_client_id"
                value={ankorstoreTestClientId}
                onChange={handleFieldChange}
                classes=" bg-green-500 "
                hide={!ankorstoreUseTestCredentials}
                placeholder=" "
              />

              <TextInput
                label="ankorstore_test_client_secret"
                id="ankorstore_test_client_secret"
                value={ankorstoreTestClientSecret}
                onChange={handleFieldChange}
                classes=" bg-green-500 "
                hide={!ankorstoreUseTestCredentials}
                placeholder="***************************************************************"
              />
            </div>
          </div>

          {props.userUpdate === "updating" ? (
            <div>Updating...</div>
          ) : (
            <div className="flex w-full">
              {/* Submit button */}
              <button
                type="submit"
                className="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              >
                Enregistrer
              </button>
            </div>
          )}
        </form>
        <hr />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  // console.log("dispatch", dispatch);
  return {
    updateUserAccount: (user_id, userData) =>
      dispatch(updateUserAccount(user_id, userData)),
  };
}
const mapStateToProps = ({ user, userUpdate }) => {
  return {
    userUpdate,
    user_id: user.id,
    email: user.email,
    phone: user.phone,
    first_name: user.first_name,
    last_name: user.last_name,
    ankorstore_use_test_credentials: user.ankorstore_use_test_credentials,
    ankorstore_base_url: user.ankorstore_base_url,
    ankorstore_client_id: user.ankorstore_client_id,
    ankorstore_client_secret: user.ankorstore_client_secret,
    ankorstore_test_base_url: user.ankorstoretest__base_url,
    ankorstore_test_client_id: user.ankorstore_test_client_id,
    ankorstore_test_client_secret: user.ankorstore_ctest_lient_secret,
    wc_use_test_credentials: user.wc_use_test_credentials,
    wc_base_url: user.wc_base_url,
    wc_client_id: user.wc_client_id,
    wc_client_secret: user.wc_client_secret,
    wc_test_base_url: user.wc_test_base_url,
    wc_test_client_id: user.wc_test_client_id,
    wc_test_client_secret: user.wc_test_client_secret,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccount);
