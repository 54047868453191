import React from "react";

import { connect } from "react-redux";

import Alert from "../ui/Alert";

const OrderSumary = (props) => {
  let displayLines = [];
  displayLines = props.verified.data.errorLines.length
    ? props.verified.data.errorLines
    : props.verified.data.orderLines;

  if (
    props.importing ||
    (props.imported &&
      props.imported.createdOrder &&
      props.imported.createdOrder.success)
  ) {
    return <div></div>;
  }

  return (
    <div className="my-4">
      {props.verified.data.isFulfillable && (
        <Alert
          message={`La commande #${props.verified.verificationRef} a été vérifiée avec les stocks WooCommerce. Elle peut etre validée et importée`}
          type="success"
        />
      )}

      {!props.verified.data.isFulfillable && (
        <Alert
          message="Il manque des stocks dans WooCommerce. Veuillez ajuster le contenu de la commande et vérifier à nouveau avant importation."
          type="danger"
        />
      )}
      <div className="overflow-x-auto">
        <table className="mb-8 w-full bg-white">
          <thead>
            <tr className="w-full bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-center">sku</th>
              <th className="py-3 px-6 text-center">Nom</th>
              <th className="py-3 px-6 text-center">
                Quantité en stock dans WooCommerce{" "}
              </th>
              <th className="py-3 px-6 text-center">Quantité commandée</th>
              <th className="py-3 px-6 text-center">Résultat</th>
            </tr>
          </thead>
          <tbody>
            {displayLines.map((line) => {
              let line_class = "";
              if (!line.ok) {
                line_class = "text-red-500";
              } else if (line.ok === "shipping_error") {
                line_class = "text-yellow-500";
              } else {
                line_class = "text-green-500";
              }

              return (
                <tr
                  key={line.id}
                  className={`w-full border-b border-gray-200 hover:bg-gray-100 ${line_class}`}
                >
                  <td className="py-3 px-6 text-center">{line.sku}</td>
                  <td className="py-3 px-6 text-center">{line.name}</td>
                  <td className="py-3 px-6 text-center">
                    {line.stock_quantity}
                  </td>
                  <td className="py-3 px-6 text-center">{line.qty}</td>
                  <td className="py-3 px-6 text-center">
                    {line.ok ? "OK" : "NO"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps({ user, imported, importing, verified, verifying }) {
  return { user, imported, importing, verified, verifying };
}
export default connect(mapStateToProps)(OrderSumary);
