import React, { useState, useEffect } from "react";
import MoonLoader from "react-spinners/ClockLoader";

import { connect } from "react-redux";
import { importAnkorstoreOrder } from "../../actions";
import Alert from "../ui/Alert";
import TextInput from "../ui/TextInput";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const ImportOrder = (props) => {
  // console.log(process.env.REACT_APP_CMS_URL);
  // console.log("ImportOrder props: ", props);

  let orderNumberInit = props.verified ? props.verified.verificationRef : "";

  // State variables for email and password inputs
  const [orderNumber, setOrderNumber] = useState(orderNumberInit);
  const [customOrderNote, setCustomOrderNote] = useState("");

  useEffect(() => {
    // Update orderNumber state if props.verified changes
    setOrderNumber(props.verified?.verificationRef || "");
  }, [props.verified?.verificationRef]);

  const [markPaid, setMarkPaid] = useState("checked");

  // Function to handle changes in email input
  const handleOrderNumberChange = (event) => {
    setOrderNumber(event.target.value);
  };

  // Function to handle changes in markPaid input
  const handleMarkPaidChange = (event) => {
    // console.log(event.target.checked)
    setMarkPaid(event.target.checked);
  };

  // Function to handle changes in markPaid input
  const handleCustomOrderNoteChange = (event) => {
    // console.log(event.target.checked)
    setCustomOrderNote(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    let ankorstoreOrderToImport = props.verified.data.ankorstoreOrderToImport;
    let orderLines = props.verified.data.orderLines;
    props.importOrder(
      ankorstoreOrderToImport,
      orderLines,
      markPaid,
      customOrderNote
    );
  };

  if (
    props.imported &&
    props.imported.createdOrder &&
    props.imported.createdOrder.success
  ) {
    return <div></div>;
  }

  if (!props.verified && !props.verifying) {
    return (
      <div>
        <Alert
          message="Veuillez vérifier le stock disponible pour la commande avant de la valider dans Ankorstor et avant de l'importer. "
          type="warning"
        />
      </div>
    );
  }

  if (
    props.verified &&
    props.verified.data &&
    !props.verified.data.error &&
    !props.verified.data.isFulfillable
  ) {
    return (
      <div>
        <Alert
          message="Certains produits manquent de stock et ne pourront pas être expédiés. Au choix : modifier la commande dans Ankorstore pour modifier les quantités des produits dans le tableau ci-contre, ou ajouter du stock WooCommerce, puis vérifier à nouveau la faisabilité de la commande. "
          type="danger"
        />
      </div>
    );
  } else if (
    props.verified &&
    props.verified.data &&
    props.verified.data.isFulfillable
  ) {
    return (
      <div>
        <div className="mx-auto flex flex-col w-full   mb-6   py-8 bg-white rounded-lg shadow dark:bg-gray-800  px-6 ">
          <div className=" text-center self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
            Importer la commande Ankorstore <b>#{orderNumber}</b>
          </div>
          {!props.importing ? (
            <div className="my-4">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="flex flex-col mb-2">
                  <div className="flex relative ">
                    {/* Order number input */}

                    <input
                      type="hidden"
                      value={orderNumber}
                      onChange={handleOrderNumberChange}
                      className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      placeholder="Référence de la commande "
                      readOnly={true}
                    />
                  </div>
                </div>

                <TextInput
                  id="custom_order_note"
                  label="Ajouter une note "
                  value={customOrderNote}
                  onChange={handleCustomOrderNoteChange}
                  placeholder="Vos commentaires ici..."
                />

                {/* Mark as Paid checkbox */}
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    id="markAsPaid"
                    checked={markPaid}
                    onChange={handleMarkPaidChange}
                    className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor="markAsPaid"
                    className="ml-2 block text-gray-900 dark:text-gray-300"
                  >
                    Marquer comme payée
                  </label>
                </div>

                <div className="flex w-full">
                  {/* Submit button */}
                  <button
                    type="submit"
                    className="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                  >
                    IMPORTER
                  </button>
                </div>
              </form>
              <hr />
            </div>
          ) : (
            <div className="my-2">
              <p className="text-center text-purple-600 mb-4">
                <em>
                  Import de la commande <b> {orderNumber}</b> en cours...{" "}
                </em>
              </p>

              <MoonLoader
                color="#8E24AA"
                loading={props.importing}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

function mapDispatchToProps(dispatch) {
  // console.log("dispatch", dispatch);
  return {
    importOrder: (
      ankorstoreOrderToImport,
      orderLines,
      markPaid,
      customOrderNote
    ) =>
      dispatch(
        importAnkorstoreOrder(
          ankorstoreOrderToImport,
          orderLines,
          markPaid,
          customOrderNote
        )
      ),
  };
}

function mapStateToProps({ importing, imported, verified, verifying }) {
  return {
    verified,
    verifying,
    importing,
    imported,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportOrder);
