import React from 'react';

const TextInput = ({
  type = 'text',
  id,
  label,
  value,
  onChange,
  placeholder = '',
  classes = '',
  hide = false
}) => {
  return (
    <div className={`flex flex-col mb-2 ${classes} rounded p-1 ${hide ? 'hidden' : ''}`}>
      <div className="flex relative">
        <span className="rounded-l-md w-1/5 inline-flex items-center p-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
          {label}
        </span>
        <input
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default TextInput;
