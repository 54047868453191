import { connect } from "react-redux";
import ImportOrder from "../forms/ImportOrder";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import Terminal from "../ui/Terminal";
import Alert from "../ui/Alert";
import SettingsSummary from "../ui/SettingsSummary";
import VerifyOrder from "../forms/VerifyOrder";
import OrderSummary from "../ui/OrderSummary";

const Dashboard = ({ user, imported, importing, verified, verifying }) => {
  // console.log(`props of Dashboard`, props);
  let wc_link_base = "";
  if (user) {
    wc_link_base =
      user.wc_use_test_credentials === true
        ? user.wc_test_base_url
        : user.wc_base_url;
  }

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if user exists and redirect if not
    if (user === false) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user == null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading)
    return (
      <div className="md:container md:mx-auto">
        <div className="p-4 text-center">Loading...</div>
      </div>
    );



  function renderContent() {
   
        return (
          <div>
            <VerifyOrder />
            <ImportOrder />
            <SettingsSummary />
          </div>
        ); 
  }

  return (
    <div className="container mx-auto block">
      <div className="md:grid lg:grid-cols-3 md:grid-cols-2 my-8 mx-2 gap-4 ">
        <div className="col-span-1  ">{renderContent()}</div>

        <div className="col-span-2 ">
          {imported && !imported.success && (
            <Alert message={imported.error} type="danger" />
          )}

          {imported && !verifying && imported.success && (
            <Alert
              message={`Commande importéé dans WooCommerce avec l'ID : ${imported.data.id}
            `}
              link={`${wc_link_base}/wp-admin/post.php?post=${imported.data.id}&action=edit`}
              cta="Voir la commande dans WooCommerce"
              cta_title="Assurez-vous d'être connectés à l'admin WooCommerce avant de cliquer sur ce lien"
              type="success"
            />
          )}

          {verified && verified.data.success && <OrderSummary />}

          <Terminal />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps({ user, imported, importing, verified, verifying }) {
  return { user, imported, importing, verified, verifying };
}
export default connect(mapStateToProps)(Dashboard);
