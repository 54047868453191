import { connect } from "react-redux";
import EditAccount from "../forms/EditAccount";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Account = ({user}) => {
  // console.log(`props of Account`, props);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if user exists and redirect if not
    if (user === false) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user == null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading)
    return (
      <div className="md:container md:mx-auto">
        <div className="p-4 text-center">Loading...</div>
      </div>
    );

  function renderContent() {
    return <EditAccount />;
  }

  return (
    <div className="container mx-auto">
      <div className="md:grid grid-cols-3 my-8 gap-4 px-2">
        <div className="col-span-3  ">{renderContent()}</div>
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { user };
}
export default connect(mapStateToProps)(Account);
