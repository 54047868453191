import React, { useState } from "react";
import MoonLoader from "react-spinners/ClockLoader";
import OrderIcon from "../icons/Order";
import Alert from "../ui/Alert";

import { connect } from "react-redux";
import { verifyAnkorstoreOrder } from "../../actions";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const VerifyOrder = (props) => {
  // console.log(process.env.REACT_APP_CMS_URL);


  // State variables for email and password inputs
  const [orderNumber, setOrderNumber] = useState("");
  const [forceImportExisting, setForceImportExisting] = useState(false);

  // Function to handle changes in email input
  const handleOrderNumberChange = (event) => {
    setOrderNumber(event.target.value);
  };

  const handleForceImportExistingChange = (event) => {
    setForceImportExisting(event.target.checked );
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {

    // console.log('forceImportExisting ' + forceImportExisting);
    event.preventDefault();
    
    props.verifyOrder(orderNumber, forceImportExisting);
  };

  if (props.importing) {
    return <div></div>;
  }

  return (
    <div>
      <div className="mx-auto flex flex-col w-full   mb-6   py-8 bg-white rounded-lg shadow dark:bg-gray-800  px-6 ">
        <div className=" text-center self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
          Vérifier une commande Ankorstore
        </div>

        {!props.verifying && !props.importing ? (
          <div className="my-4">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="flex flex-col mb-2">
                <div className="flex relative ">
                  {/* Order number input */}
                  <span className="rounded-l-md dark:bg-gray-800 inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                    <OrderIcon />
                  </span>
                  <input
                    type="text"
                    value={orderNumber}
                    onChange={handleOrderNumberChange}
                    className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Référence de la commande "
                  />
                </div>
              </div>

              {props.verified && props.verified.data.alreadyImported && (
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    id="forceImportExisting"
                    checked={forceImportExisting}
                    onChange={handleForceImportExistingChange}
                    className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor="forceImportExisting"
                    className="ml-2 block text-red-500 dark:text-red-300"
                  >
                    Ignorer les imports existants et forcer la vérification et
                    l'import de cette commande
                  </label>
                </div>
              )}

              <div className="flex w-full">
                {/* Submit button */}
                <button
                  type="submit"
                  className="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Vérifier la commande
                </button>
              </div>
            </form>
            <hr />
          </div>
        ) : (
          <div className="my-2">
            <p className="text-center text-purple-600 mb-4">
              <em>
                {props.importing ? "Importation " : "Verifification "}
                de la commande <b> {orderNumber}</b> en cours...{" "}
              </em>
            </p>

            <MoonLoader
              color="#8E24AA"
              loading={props.verifying}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>

      {props.verified && props.verified.data && props.verified.data.error && (
        <div>
          <Alert
            message={props.verified.data.error}
            type="danger"
            cta={props.verified.data.alreadyImported ? "Voir L'historique" : ""}
            cta_title={props.verified.data.alreadyImported ? "Voir L'historique" : ""}
            link={props.verified.data.alreadyImported ? "/history" : ""}
          />
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  // console.log("dispatch", dispatch);
  return {
    verifyOrder: (orderNumber, forceImportExisting) =>
      dispatch(verifyAnkorstoreOrder(orderNumber, forceImportExisting)),
  };
}

function mapStateToProps({ importing, verifying, verified }) {
  return {
    verifying,
    verified,
    importing,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOrder);
